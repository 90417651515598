import axios from "axios";
import store from "@/store/index";
import Vue from "vue";
import { getCurrentTimezone } from "@/utils/timezone";

const api = () => {
  const token = store.getters["login/token"];
  const api = axios.create({
    baseURL: process.env.VUE_APP_API || "https://stag-api.appbio.com.br",
    // baseURL: "http://localhost:3000",
    // baseURL: "https://api.appbio.com.br",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-TimeZone": getCurrentTimezone(),
    },
    timeout: 300000,
  });

  api.interceptors.response.use(
    (conf) => {
      return Promise.resolve(conf);
    },
    (error) => {
      if (error?.response?.status === 401) {
        Vue.toasted.global.error({
          message: "Sessão expirada. Faça o login novamente!",
        });
        store.dispatch("login/logout");
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export default api;
